import React, { useState } from 'react';
import { Drawer } from 'antd';

import { CustomForm } from '../../base_components/form_component';

import {
    formKeysLabelsObj,
    formKeySelectComponentsObj,
    formKeysHandleButtonObj,
    formGetRequestParamsKeys,
    formInitialObj
} from './banners_columns_label_config';
import { GoodsOptModal } from '../../modals/goods_opt';

// 列表查询 form

const BannersQueryDrawerForm = ({ visible, setVisible, queryHandle }) => {
  const [initFormCount, setInitFormCount] = useState(0);

  // 选择 modal
  const [optModalState, setOptModalState] = useState(0);
  const [updateFormObj, setUpdateFormObj] = useState({});
  const selectGoodsHandle = (goodsObj) => {
      // 选择商品操作
      console.log(goodsObj);
      setUpdateFormObj({
          goods_id: goodsObj.id,
      });
      setOptModalState(0);
  };

  const closeDrawer = () => {
    // 关闭查询抽屉
    setInitFormCount(initFormCount + 1);
    setVisible(false);
  };

  const onFinish = (formObj) => {
    // 提交查询 根据具体情况更新 fromObj
    // ~~~~~~~ 具体情况更新 fromObj ~~~~~~
    let copyFormObj = { ...formObj };
    console.log(copyFormObj);
    queryHandle(copyFormObj);
    setTimeout(() => {
      closeDrawer();
    }, 50);
  };

  return (
    <>
      <Drawer
        title="Banners查询"
        width="100%"
        height="400px"
        placement="top"
        onClose={closeDrawer}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
        footer={null}
      >
        <CustomForm
          columns={formGetRequestParamsKeys}
          formKeyLabelObj={formKeysLabelsObj}
          formKeySelectComponentsObj={formKeySelectComponentsObj}
          formKeysItemButtonObj={formKeysHandleButtonObj(setOptModalState)}
          onFinish={onFinish}
          cancelHandle={() => setVisible(0)}
          showDeleteButton={false}
          formInitialObj={formInitialObj}
          initFormCount={initFormCount}
          updateFormObj={updateFormObj}
        ></CustomForm>
      </Drawer>

      <GoodsOptModal
          modalState={optModalState === 1}
          setModalState={setOptModalState}
          selectHandle={selectGoodsHandle}
      ></GoodsOptModal>
    </>
  );
};

BannersQueryDrawerForm.defaultProps = {
  visible:false,
  setVisible: (booleanValue) => { console.log(booleanValue); },
  queryHandle: (queryParamsObj) => { console.log(queryParamsObj); }
};

export default BannersQueryDrawerForm;
