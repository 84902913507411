import React, { useState, useEffect } from 'react';

import { formKeysLabelsObj } from './banners_columns_label_config';

// 显示查询条件组件

const BannersQueryParams = ({ paramsObj={} }) => {
  const [displayQueryFactorStr, setDisplayQueryFactorStr] = useState('');

  useEffect(() => {
    displayStr();
  }, [paramsObj])

  const displayStr = () => {
    // 显示的查询条件 字符串
    // let retDisplayStr = '查询条件:';
    let retDisplayStr = '';
    Object.keys(paramsObj).forEach(element => {
      if (Object.keys(formKeysLabelsObj).includes(element)) {
        // 只显示 formKeysLabelsObj 包含的key
        const currentFactor = ' ' + formKeysLabelsObj[element] + ' = ' + paramsObj[element] + ';';
        retDisplayStr = retDisplayStr + currentFactor
      }
    });
    if (retDisplayStr.length > 2) {
      retDisplayStr = '查询条件:' + retDisplayStr;
      setDisplayQueryFactorStr(retDisplayStr);
    } else {
      console.log('查询条件为空')
      setDisplayQueryFactorStr('');
    }
  }
  return (
    <div style={{ height: 'auto', width: '100%', padding: '0.1rem 0.2rem' }}>
      {displayQueryFactorStr.length > 2
        ? (<p>{displayQueryFactorStr}</p>)
        : null}
    </div>
  )
};

export default BannersQueryParams;
