import React, { useRef, useState, useEffect } from 'react';
import { Modal, Divider, message } from 'antd';
import moment from 'moment';

import { StsUploadImagesList } from '../../upload_files/sts_upload_files_list';
import { BannersRequestPost } from './banners_requests';
import { CustomForm } from '../../base_components/form_component';
import { GoodsOptModal } from '../../modals/goods_opt';

import {
    formKeys,
    formDisabledKeys,
    formNotShowKeys,
    formKeysLabelsObj,
    formKeySelectComponentsObj,
    formKeysHandleButtonObj,
    formInitialObj,
    formRulesList,
    imageKeyNames,
    maxImagesNum
} from './banners_columns_label_config';

// 新建 Modal

const CreateBanners = ({ visible, setVisible, reloadListCount, setReloadListCount }) => {

    const bannersImageObj = useRef({});
    const oldObj = useRef({handleCount: 0});

    const [initFormCount, setInitFormCount] = useState(0);

    const [postRequestCount, setPostRequestCount] = useState(0);
    const reqObject = useRef({});

    useEffect(() => {
        setInitFormCount(initFormCount + 1);  // 初始化表格
    }, [visible]);

    // 选择 modal
    const [optModalState, setOptModalState] = useState(0);
    const [updateFormObj, setUpdateFormObj] = useState({});
    const selectGoodsHandle = (goodsObj) => {
        // 选择商品操作
        console.log(goodsObj);
        setUpdateFormObj({
            goods_id: goodsObj.id,
        });
        setOptModalState(0);
    };

    const postResponseDataHandle = (respObj) => {
        // 表单提交后的操作
        if (
            Object.keys(respObj).includes('code')
            && respObj.code === 1000
        ){
            closeModal();
            setReloadListCount(reloadListCount + 1)
        } else {
            message.error('网络错误...');
        }
    }

    const closeModal = () => {
        // 初始化表格和图片 并关闭Modal
        oldObj.current = {handleCount: oldObj.current.handleCount + 1};  // 初始化图片
        setVisible(0);
    }

    const bannersImageUploaded = (imagesObj) => {
        // 图片上传后操作
        bannersImageObj.current = { ...imagesObj };
        console.log(imagesObj);
    }

    const onFinish = (values) => {
        // 提交表单
        let upObj = { ...values, ...bannersImageObj.current };
        console.log(upObj);
        // 时间转时间戳
        upObj.end_time = parseInt(moment.unix(upObj.end_time).unix() / 1000);  // 时间转时间戳
        console.log(upObj.end_time);
        reqObject.current = upObj;
        setPostRequestCount(postRequestCount + 1);
    }

    return (<>
        <Modal visible={visible === 1}
            title="Banners 新建"
            closable={false}
            footer={null}
        >
            <div style={{ width: '100%', height: 'auto', textAlign: 'center' }}>
                <StsUploadImagesList
                    oldObj={oldObj.current}
                    maxImagesNum={maxImagesNum}
                    imageKeyName={imageKeyNames[0]}
                    onChange={bannersImageUploaded}
                ></StsUploadImagesList>
            </div>
            <Divider></Divider>

            <CustomForm
                columns={formKeys}
                disabledKeys={formDisabledKeys}
                notShowKeys={formNotShowKeys}
                formKeyLabelObj={formKeysLabelsObj}
                formKeySelectComponentsObj={formKeySelectComponentsObj}
                formKeysItemButtonObj={formKeysHandleButtonObj(setOptModalState)}
                onFinish={onFinish}
                cancelHandle={closeModal}
                showDeleteButton={false}
                formInitialObj={formInitialObj}
                initFormCount={initFormCount}
                rulesObj={formRulesList}
                updateFormObj={updateFormObj}
            ></CustomForm>

            <GoodsOptModal
                modalState={optModalState === 1}
                setModalState={setOptModalState}
                selectHandle={selectGoodsHandle}
            ></GoodsOptModal>

            <BannersRequestPost
                requestCount={postRequestCount}
                reqObject={reqObject.current}
                responseDataHandle={postResponseDataHandle}
            ></BannersRequestPost>
        </Modal>
    </>)
}

CreateBanners.defaultProps = {
  visible: false,
  setVisible: (val) => console.log(val),
  reloadListCount: 0,
  setReloadListCount: (booleanVal) => console.log(booleanVal)
}

export default CreateBanners;
