import React from "react";

import SiteLayout from '../components/site_layout';
import SEO from "../components/seo";

import BannersList from '../components/wx_store/banners';

const StoreManagePage = () => (
  <SiteLayout>
    <SEO title="WX Banner" />
    <BannersList></BannersList>
  </SiteLayout>
)

export default StoreManagePage;
