import React from 'react';
import { Button, DatePicker } from 'antd';
import moment from 'moment';
import { CustomSelect } from '../../base_components/select_component';
// import { expressCompanyRequestGet } from './custom_select_requests';
import { customRanderItem } from '../../base_components/lists/custom_list';

// Banners 列 配置

// ~~~~~~ 表单列, 需添加 ~~~~~~
const formKeys = [
    "id", "enabled", "end_time", "goods_id", "image", "is_delete",
    "link", "link_type", "sort_order", "handle_staff_id", "add_time", "update_time", "explains"
];

// ~~~~~~ 表单不能编辑列, 根据实际添加 ~~~~~~
const formDisabledKeys = [
    "id", "image", "goods_id", "handle_staff_id", "add_time", "update_time"
];

// ~~~~~~ 表单不显示列, 根据实际添加 ~~~~~~
const formNotShowKeys = [
    "image", "enabled", "link", "link_type", "handle_staff_id"
];

// ~~~~~~ 表单列名称 label, 需添加 ~~~~~~
const formKeysLabelsObj = {
    "id": "ID", "enabled": "可编辑", "end_time": "结束时间", "goods_id": "商品ID",
    "image": "图片", "is_delete": "不显示", "link": "链接", "link_type": "链接类型", "sort_order": "排序次序", 
    "handle_staff_id": "编辑人员", "explains": "说明", "add_time": "创建日期", "update_time": "更新日期"
};

//  ~~~~~~ 选择框, 根据实际添加 ~~~~~~
const SelectBannerEnabled = (<CustomSelect
    options={[{ value: 0, label: '不可编辑' }, { value: 1, label: '可编辑' }]} />);
const SelectBannerLinkType = (<CustomSelect
    options={[{ value: 0, label: '无效' }, { value: 1, label: '有效' }]} />);
const SelectIsDelete = (<CustomSelect 
    options={[{ value: 0, label: '否'}, { value: 1, label: '是'}]} />)
const SelectEndTime = (<DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />);
const formKeySelectComponentsObj = {
    enabled: SelectBannerEnabled,
    end_time: SelectEndTime,
    is_delete: SelectIsDelete,
    link_type: SelectBannerLinkType,
};

// ~~~~~~ 表单自定义操作按钮, 根据实际添加 ~~~~~~
const formKeysHandleButtonObj = (setOptModalState) => {
    return {
        goods_id: {buttonTips: '选择商品', clickMethod: () => setOptModalState(1)},
    };
};

// ~~~~~~ 表单是图片列, 根据实际添加(废弃, 改用 imageKeyNames) ~~~~~~
// const formKeysIsImages = [];

// ~~~~~~ 表单 get 请求条件key, 根据实际添加 ~~~~~~
const formGetRequestParamsKeys = [];

//  ~~~~~~ 表单初始化值, 根据实际添加 ~~~~~~
const formInitialObj = {
    "id": 0, "enabled": 0, "goods_id": 0, "end_time": "", "is_delete": 0, "link": "", "link_type": 0,
    "sort_order": 0, "explains": "", "add_time": "", "update_time": ""
}

// ~~~~~~ 表单值规则, 根据实际添加 ~~~~~~
// formRulesList = {formKey: [{require: true, message: 'Please input Password!',}, ]};
const formRulesList = {"password": [{required: true, message: 'Please input Password!',}, ], }

// ~~~~~~ 图片列名称, 根据实际修改(多张图片去掉最后序号数字) ~~~~~~
const imageKeyNames = ['image'];

// ~~~~~~ 图片总数量, 默认设置为1, 根据实际修改 ~~~~~~
const maxImagesNum = 1;

// ~~~~~~ api请求url apiLists, 根据实际修改 ~~~~~~
const apiLists = ['banner-admin/v1/banners', ];

const actionButtons = (item, setVisible, setSelectedObj, selectButtonOnClick) => {
    const onClickInfo = () => {
        setSelectedObj(item);
        setVisible(2);
    }
    const btnList = [
        <Button onClick={onClickInfo} type="link">详情</Button>,
    ];
    if (selectButtonOnClick) {
        btnList.push(<Button onClick={() => selectButtonOnClick(item)} type="link">选择</Button>)
    }
    return btnList;
}
// 修改 ~~~~~~~ item keys ~~~~~~;
const renderItem = (item, setSelectedObj, selectButtonOnClick, setVisible, visible) => {
    // 必须 每一行渲染 的组件, 修改 ~~~~~~~ item keys ~~~~~~;
    const updateItemObj = {
        id: item.id,
        actions: actionButtons(item, setVisible, setSelectedObj, selectButtonOnClick),
        img: item.image_url,
        avatar: '',
        title: `商品ID: ${item.goods_id}`,
        description: `结束时间: ${moment.unix(item.end_time).format('YYYY-MM-DD HH:mm:ss')}`,
        content: item.explains,
    };
    return customRanderItem(updateItemObj, 180);
};

const judgeHasMore = (resp) => {
    if (Object.keys(resp).includes('next')
        && typeof resp.next === 'string'
        && resp.next.length > 5) {
        return true;
    }
    return false;
};

export {
    formKeys,
    formDisabledKeys,
    formNotShowKeys,
    formKeysLabelsObj,
    formKeySelectComponentsObj,
    formKeysHandleButtonObj,
    // formKeysIsImages,
    formGetRequestParamsKeys,
    formInitialObj,
    formRulesList,
    imageKeyNames,
    maxImagesNum,
    apiLists,
    renderItem,
    judgeHasMore
};
