import React, { useState, useRef } from 'react';
// import { navigate, Link } from 'gatsby';
import { Button, Row, Col, Space, Tooltip } from 'antd';
import { ReloadOutlined, SearchOutlined, LeftOutlined } from '@ant-design/icons';

import CreateBanners from './create_banners';
import BannersQueryDrawerForm from './banners_query_form';
import BannersList from './banners_list';
import BannersQueryParams from './banners_query_params_display';
// Banners 入口组件

const BannersComponent = ({ selectButtonOnClick = null }) => {
  const reqParams = useRef({ page: 0 });

  const [reloadListCount, setReloadListCount] = useState(1);

  const [drawerVisible, setDrawerVisible] = useState(false);

  const [createBannersVisible, setCreateBannersVisible] = useState(false);

  const queryHandle = (currentRequestParams) => {
    // 查询操作
    reqParams.current = { ...currentRequestParams };
    // 触发重新读取数据
    setReloadListCount(reloadListCount + 1)
    // console.log('search...');
  };

  const initReloadList = () => {
    // 刷新列表, 删除所有查询条件
    reqParams.current = { page: 0 };
    setReloadListCount(reloadListCount + 1);
  }

  return (
    <div>
      <Row>
        <Col span={14}>
          <Space>
            {/* <Tooltip title="返回">
              <Link to="/logistic"><LeftOutlined /></Link>
            </Tooltip> */}
            <h4>Banners</h4>
            <Tooltip title="刷新">
              <Button onClick={initReloadList} shape="circle" icon={<ReloadOutlined />}></Button>
            </Tooltip>
            <Tooltip title="搜索">
              <Button onClick={() => setDrawerVisible(true)} shape="circle" icon={<SearchOutlined />}></Button>
            </Tooltip>
          </Space>
        </Col>
        <Col span={10}>
          <div style={{ width: '100%', height: 'auto' }}>
            {/* <Button style={{ float: 'right' }} type="link" onClick={() => navigate('/sendexpressaccount')}>快递帐号设置</Button> */}
            <Button style={{ float: 'right' }} type="link" onClick={() => setCreateBannersVisible(1)}>新建</Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}><BannersQueryParams paramsObj={reqParams.current}></BannersQueryParams></Col>
      </Row>
      <BannersQueryDrawerForm
        visible={drawerVisible}
        setVisible={setDrawerVisible}
        queryHandle={queryHandle}
      ></BannersQueryDrawerForm>

      <BannersList
        reloadListCount={reloadListCount}
        setReloadListCount={setReloadListCount}
        requestParams={reqParams.current}
        selectButtonOnClick={selectButtonOnClick}
      ></BannersList>

      <CreateBanners
        visible={createBannersVisible}
        setVisible={setCreateBannersVisible}
        reloadListCount={reloadListCount}
        setReloadListCount={setReloadListCount}
      ></CreateBanners>
    </div>
  )
};

export default BannersComponent;
